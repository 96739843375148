import React from "react"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import Layout from "@components/layout"
import SEO from "@components/seo"
import marked from "marked"

class PodcastTemplate extends React.Component {
  render() {
    const { post } = this.props.data
    const { previous, next } = this.props.pageContext
    // console.log(previous, next)
    return (
      <Layout>
        <SEO title={post.title} description={post.excerpt} />
        <div class="page-headline">
          <div class="container">
            <div class="section-heading text-center">
              
              <h4 className="text-white mb-0">{post.subtitle}</h4>
              <h1>{post.title}</h1>
              <h5 class="font-weight-bold text-uppercase text-orange flair mt-0">
                A podcast brought to you by the NARM® Training Institute
              </h5>
            </div>
          </div>
        </div>
        <div className="page-content podcast-episode">
          <Container>
            <div class="row">
              <div class="col-md-8 px-5 px-md-3">
                <div className="content">
	              <iframe
                  title={post.title}
                  src={post.podcastUrl}
                  height={90}
                  width="100%"
                  scrolling="no"
                  allowFullScreen
                  webkitallowfullscreen
                  mozallowfullscreen
                  oallowfullscreen
                  msallowfullscreen
                />
	              <div className="mt-4"
                  dangerouslySetInnerHTML={{
                    __html: marked(post.body),
                  }}
                />
                </div>
              </div>
              <div className="col-md-4 px-5">
              	{post.host.length ? (
              		<div className="hosted mb-4 bg-primary p-3">
		              	<h5 className="text-white-50 mt-0">Hosted By:</h5>
		              	{post.host.map((hosts, index) => (
		              		<div className="d-flex align-items-center">
			              		<img className="rounded-circle" src={hosts.image !== null ? hosts.image.asset.fixed.src : "https://i.pravatar.cc/50?img=12"} alt={hosts.name}/>
			              		<p className="mb-0 ml-3 font-weight-bold text-white">{hosts.name}</p>
		              		</div>
		              	))}	
	              	</div>
              	) : ""}
              	<div className="subscribe-episode mb-4 p-3 border">
              		<h5 className="mb-4">Subscribe for All Episodes on your Favorite Service:</h5>
              		<div className="text-center">
              			<p>
              			<a href="https://podcasts.apple.com/us/podcast/transforming-trauma/id1496190024">
              			<img className="img-fluid" width={200} src="/assets/img/apple-podcasts.png" alt="spotify"/></a></p>
              		</div>
              		<div className="text-center">
              			
              			<p>
              			<a href="https://open.spotify.com/show/3bdIUoZPpObByi39pn96sN?si=5911RpXmQgqEyY0OqBksPA" target="_blank" rel="noopener noreferrer">
              				<img className="img-fluid" width={200} src="/assets/img/spotify.png" alt="spotify"/>
              			</a></p>
              		</div>
              		<div className="text-center">
              			<p><a href="https://www.stitcher.com/podcast/transforming-trauma" target="_blank" rel="noopener noreferrer">
              			<img className="img-fluid" width={200} src="/assets/img/stitcher.png" alt="stitcher"/></a></p>
              		</div>
              	</div>
              	<div className="connect-with-us p-3 border">

              	<h5 className="mb-4">We want to connect with you!</h5>
              	<ul className="list-unstyled mb-4" id="side-social">
                  <li>
                    <a href="https://www.facebook.com/NARMTraining/">
                      <img
                        src="/assets/img/002-facebook.svg"
                        width={20}
                        height={20}
                        alt="facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/http://twitter.com" target="_blank" rel="noopener noreferrer">
                      <img
                        src="/assets/img/001-twitter.svg"
                        width={20}
                        height={20}
                        alt="twitter"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCezENiBJtJQwTAw2wNomLVg" target="_blank" rel="noopener noreferrer">
                      <img
                        src="/assets/img/003-youtube.svg"
                        width={20}
                        height={20}
                        alt="youtube"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/thenarmtraininginstitute/?hl=en" target="_blank" rel="noopener noreferrer">
                      <img
                        src="/assets/img/004-instagram.svg"
                        width={20}
                        height={20}
                        alt="instagram"
                      />
                    </a>
                  </li>
                </ul>
              
								<p className="font-weight-bold mb-0">Learn more about The NARM Training Institute:</p>
								<p><a href="http://www.narmtraining.com" target="_blank" rel="noopener noreferrer">http://www.narmtraining.com</a></p>

								<p className="font-weight-bold mb-0">Sign up for a free preview of The NARM Inner Circle Online Membership Program:</p>
								<p><a href="http://www.narmtraining.com" target="_blank" rel="noopener noreferrer">http://www.narmtraining.com/freetrial</a></p>
              	</div>
              </div>
            </div>

            <div class="blog-nav mt-5">
              <div class="row">
                <div class="col-md-6 text-md-left px-5 px-md-3">
                  {previous ? (
                    <div>
                      <span class="font-weight-bold text-uppercase text-muted d-block small">
                        Previous
                      </span>
                      <Link to={`/transformingtrauma/${previous.slug.current}`}>{previous.title}</Link>
                    </div>
                  ) : null}
                </div>
                <div class="col-md-6 text-md-right px-5 px-md-3">
                  {next ? (
                    <div>
                      <span class="font-weight-bold text-uppercase text-muted d-block small">
                        next
                      </span>
                      <Link to={`/transformingtrauma/${next.slug.current}`}>{next.title}</Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default PodcastTemplate

export const podcastQuery = graphql`
  query podcastQueryTemplateQuery($id: String!) {
    post: sanityPodcast(id: { eq: $id }) {
      id
      publishedAt(formatString: "MMMM DD, YYYY")
      body
      title
      subtitle
      podcastUrl
      host {
        id
        name
        image {
          asset {
            fixed(width: 50, height: 50) {
              src
            }
          }
        }
      }
    }
  }
`
